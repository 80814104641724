import {
  Component,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CurrencyPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '@environment/environment';
import {
  CdnLoaderService,
  JQUERY350,
  NUVEI_CHECKOUT,
} from '@core/services/cdn-loader';

import { SessionStorageService } from '@core/services/session-storage.service';
import { PaymentConfigModel } from '@core/models/payment-config.model';
import { ExpansionPanelComponent } from '@payment-app/components/payment-methods/expansion-panel/expansion-panel.component';
import { GeneralAuditService } from '@payment-app/core/services/general-audit.service';
import { PaymentRequestService } from '../../../../../payment/payment-request/payment-request.service';
import {
  FormPayment,
  PaymentMethod,
  PaymentMethods,
} from '@payment-app/core/models';
import { NuveiCardService } from './nuvei-card.service';
import { NuveiCardReferenceRequest, NuveiCardResponse } from './interfaces';
import { CardFormComponent } from '@payment-app/components/payment-methods/card-form/card-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CardForm } from '@payment-app/components/payment-methods/card-form/card-form.interface';

declare const PaymentCheckout: any;

@Component({
  selector: 'nuvei-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatButtonModule,
    ExpansionPanelComponent,
    CardFormComponent,
    NgIf,
  ],
  templateUrl: './nuvei-card.component.html',
  styleUrls: ['./nuvei-card.component.scss'],
})
export class NuveiCardComponent implements OnDestroy, OnInit {
  @Input() isCreditCard!: boolean;
  readonly #router = inject(Router);
  readonly #cdnLoaderService = inject(CdnLoaderService);
  readonly #nuveiCardService = inject(NuveiCardService);
  readonly #generalAuditService = inject(GeneralAuditService);
  readonly #paymentRequestService = inject(PaymentRequestService);
  paymentRequest!: PaymentConfigModel;
  #subscription: Subscription;
  protected paymentMethod!: PaymentMethod;

  protected nuveiCardFormGroup: FormGroup<CardForm>;
  protected showCheckout = false;

  // TODO: only for testing
  readonly #activatedRoute = inject(ActivatedRoute);
  isTesting = false;
  errorMessage!: string | null;

  constructor(
    private sessionStorageService: SessionStorageService,
    private ngZone: NgZone,
  ) {
    this.#cdnLoaderService.load(JQUERY350, NUVEI_CHECKOUT).then();
    this.isTesting = this.#activatedRoute.snapshot.queryParams.test === 'true';
  }

  ngOnInit(): void {
    this.initNuveiCardForm();

    this.paymentMethod = this.isCreditCard
      ? PaymentMethods.get(FormPayment.CREDIT_CARD)
      : PaymentMethods.get(FormPayment.DEBIT_CARD);

    this.#paymentRequestService.paymentRequest$.subscribe((paymentRequest) => {
      this.paymentRequest = paymentRequest;
    });
    this.paymentMethod.disabled = this.disabledMethod();
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }

  async openModal() {
    let requestAudit;
    let responseAudit;
    let urlAudit;
    const referenceAudit = this.paymentRequest.data.reference;

    // noinspection JSUnresolvedReference
    const paymentCheckoutModal = new PaymentCheckout.modal({
      env_mode: environment.paymentezEnviromentMode,
      onOpen: () => {},
      onClose: () => {
         this.#generalAuditService
           .save({
             description: 'AUDITORIA PAGO CON TARJETA NUVEI',
             request: requestAudit,
             response: responseAudit,
             url: urlAudit,
             reference: referenceAudit,
           })
           .subscribe((_) => {});
      },
      onResponse: async (response: NuveiCardResponse) => {
        responseAudit = response;

        const transaction = response.transaction;
        transaction.franchise = response.card.type;

        if (this.paymentRequest.dataRetentions) {
          transaction.dataRetentions = this.paymentRequest.dataRetentions;
        }

        this.#nuveiCardService
          .postUpdateNuveiTransaction(transaction)
          .subscribe((_) => {
            this.ngZone.run(() => {
              this.#router.navigate([
                `pagos/volver-al-comercio/card-nuvei/${this.paymentRequest.data.reference}`,
              ]);
            });
          });

        return true;
      },
    });

    // Se crea el body para la petición de la referencia
    const data = this.paymentRequest.data;

    const nuveiCardReferenceRequest: NuveiCardReferenceRequest = {
      amount: data.amount,
      description: data.description,
      vat: data.vat,
      reference: data.reference + '(' + this.paymentRequest.name + ')',
      userId: data.id,
      email: data.email,
      installments: this.paymentMethod.helpText === 'card_help' ? 0 : -1,
      dataRetentions: this.paymentRequest.dataRetentions,
    };

    requestAudit = nuveiCardReferenceRequest;

    this.#subscription = this.#nuveiCardService
      .getReference(nuveiCardReferenceRequest)
      .subscribe({
        next: (response) => {
          responseAudit = response;
          urlAudit = response.checkout_url;

          paymentCheckoutModal.open({reference: response.reference});
        },
        error: (error) => {
          this.errorMessage = error.error.error.error.description + ' ' + error.error.error.error.type;
          setTimeout(() => {
            this.errorMessage = null;
          }, 3000);
      }});

    window.addEventListener('popstate', () => {
      paymentCheckoutModal.close();
    });
  }

  get amount() {
    return this.paymentRequest.aplly_bonus
      ? this.paymentRequest?.data?.amount
      : this.paymentRequest?.data?.amount;
  }

  onSaveCard(value: boolean) {
    this.showCheckout = !value;
  }

  private initNuveiCardForm() {
    this.nuveiCardFormGroup = new FormGroup<CardForm>({
      idType: new FormControl<string>(''),
      idValue: new FormControl<string>('', [Validators.required]),
      customerName: new FormControl<string>(''),
      customerEmail: new FormControl<string>(''),
      cardNumber: new FormControl<string>(''),
      cardExpirationMonth: new FormControl<string>(''),
      cardExpirationYear: new FormControl<string>(''),
      cardCvv: new FormControl<number | null>(null),
      installments: new FormControl<number>(0),
    });
  }

  private disabledMethod() {

    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length ) {
      if (this.paymentRequest.data.subclient) {
        return false;
      }
      return true;
    }
    return false;
  }
}
